<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div>
      <div class="container-fluid">
        <div class="row mt-4">
          <div class="col-lg-4 col-md-6 col-sm-12 mt-3">
            <!-- <div class="card minicards">
              <div class="card-body d-flex justify-content-between">
                <div class="info text-center p-2 mt-3">
                  <span class="info_logo" ><img src="../../assets/img/Group 78.png" alt=""></span><br>
                  <span class="staff_counter">My Profille</span>
                </div>
               
                <div class="button" style="margin-top: 16px;cursor: pointer;">
                  <button class="btn" 
                  @click.prevent="redirectRoute(`/parent/student/portal/view/details/${$route.params.id}`)"
                    style="height: 68px;
                    width: 78px;
                    border-radius: 50%;
                    border: 1px solid #2E2E2E;
                    font-size: 14px;
                    background-color: #b5b5eb">View</button>
                </div>

              </div>
            </div>
            <div class="card minicards mt-4">
              <div class="card-body d-flex justify-content-between">
                <div class="info text-center p-2 mt-3">
                  <span class="info_logo"><img src="../../assets/img/Group 10.png" alt=""></span><br>
                  <span class="staff_counter">Notification</span>
                </div>
                <div class="button" style="margin-top: 16px;cursor: pointer;">

                  <button class="btn" @click.prevent="redirectRoute(`/parent/student/alerts/${$route.params.id}`)" style="height: 68px; 
                    width: 78px;
                    border-radius: 50%;
                    border: 1px solid #2E2E2E;
                    font-size: 14px;
                    background-color: #b5b5eb">{{ alertList.length }}</button>
                </div>

              </div>
            </div> -->
            <div class="card">
              <div class="card-body">
                <p>
                  <span class="d-flex align-items-center gap-2 card_left_text">
                    <span style="margin-left: 3px"> Attendance</span></span
                  >

                  <span class="veiw_all">
                    <a
                      style="color: blue"
                      @click.prevent="
                        navToLeave(
                          `/parent/student/attendance/view/${$route.params.id}`
                        )
                      "
                      >View All</a
                    >
                  </span>
                </p>
                <br />

                <div class="row">
                  <div class="col-lg-2 col-md-6 col-sm-12"></div>
                  <div
                    class="col-lg-8 col-md-6 col-sm-12"
                    style="text-align: center"
                  >
                    <div class="mt-3">
                      <span class="chec_kin">Today status</span>:
                      <!-- <span class="day">Absent</span> -->
                      <span
                        :style="
                          attendanceDetails &&
                          attendanceDetails.status == 'Present'
                            ? 'color: rgb(18, 237, 18);'
                            : 'color: red;'
                        "
                        >{{
                          attendanceDetails && attendanceDetails.status
                            ? attendanceDetails.status
                            : "Absent"
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12"></div>
                </div>
                <!-- <div class="cin_cout flex-wrap justify-content-between" style="text-align: center;">
                 
                  <div class="mt-3">

                    <span class="chec_kin">Today status</span>: 
                    <span :style="attendanceDetails && attendanceDetails.status == 'Present' ? 'color: rgb(18, 237, 18);' : 'color: red;' ">{{ attendanceDetails && attendanceDetails.status ? attendanceDetails.status : 'Absent' }}</span>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-body">
                <p>
                  <span class="d-flex align-items-center gap-2 card_left_text"
                    ><span style="margin-left: 3px"> Leaves</span></span
                  >
                  <span class="veiw_all">
                    <a
                      style="color: blue"
                      @click.prevent="
                        navToLeave(
                          `/parent/student/leave/request/${$route.params.id}`
                        )
                      "
                      >View All</a
                    >
                  </span>
                </p>
                <br />

                <div class="row">
                  <div class="col-lg-2 col-md-6 col-sm-12"></div>
                  <div
                    class="col-lg-8 col-md-6 col-sm-12"
                    style="text-align: center"
                  >
                    <button
                      type="button"
                      class="btn btnsml mt-2"
                      id="svbtn"
                      style="width: 60%"
                      @click.prevent="openLeavePopUp"
                    >
                      <span>Apply Leave</span>
                    </button>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12"></div>
                </div>
                <!-- <div class="cin_cout flex-wrap justify-content-between" style="text-align: center;">
                  <button type="button" class="btn btnsml mt-2" id="svbtn" style="width: 42%;"
                  @click.prevent="openLeavePopUp">
                    <span>Apply Leave</span>
                  </button>
                
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mt-3">
            <div class="card">
              <div class="card-body">
                <p>
                  <span class="d-flex align-items-center gap-2 card_left_text"
                    ><span style="margin-left: 3px">
                      Certificate Request</span
                    ></span
                  >
                  <span style="float: right">
                    applied: <span style="font-weight: bold">{{  onlineAdmissionCount  }}</span></span
                  >
                </p>
                <br />
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <!-- <div class="no-org no-cirlce">
                {{ onlineAdmissionList.length }}
              </div> -->
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <button
                      type="button"
                      class="btn btnsml mt-2"
                      id="svbtn"
                      style="width: 120%"
                      @click.prevent="
                        redirectRoute(`/parent/student/tc/${$route.params.id}`)
                      "
                    >
                      <span>Request</span>
                    </button>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12"></div>
                </div>

                <!-- <div class="cin_cout flex-wrap justify-content-between" style="text-align: center;">
                  <button type="button" class="btn btnsml mt-2" id="svbtn" style="width: 30%;"
                  @click.prevent="redirectRoute(`/parent/student/tc/${$route.params.id}`)">
                    <span>Request</span>
                  </button>
                
                </div> -->
              </div>
            </div>
            <div class="card mt-4">
              <div class="card-body">
                <p>
                  <!-- <span class="info_logo" ><img src="../../assets/img/Group 78.png" alt=""></span><br>
                  <span class="staff_counter">My Profille</span> -->

                  <span class="d-flex align-items-center gap-2 card_left_text">
                    <span style="margin-left: 3px"> Fee Payment</span></span
                  >

                  <span style="float: right">
                    Adhoc Fee: <span :style="!isPendingAdhocfee ? 'font-weight: bold;color: #1BB75A;' : 'font-weight: bold;color: #F62828;'">{{  isPendingAdhocfee ? 'Due' : isPendingAdhocfeeStatus == 'Paid' ? 'Paid' : '-' }}</span></span
                  >
                </p>
                <br />
                <div class="row">
                  <div
                    class="col-lg-4 col-md-6 col-sm-12"
                    style="text-align: center; margin-top: 11px;font-size: small;"
                  >
                  Fee Term: {{totalFeePaidTerms}}/{{  totalFeeTerms }}
                    <!-- <div class="no-org no-cirlce">
                {{ onlineAdmissionList.length }}
              </div> -->
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <button
                      type="button"
                      class="btn btnsml mt-2"
                      id="svbtn"
                      style="width: 100%"
                      @click.prevent="
                      redirectRoute(
                        `/parent/student/feePayment/${$route.params.id}`
                      )
                    "
                    >
                      <span>Check</span>
                    </button>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <span style="float: right; margin-top: 9px;font-size: small;" >
                      Next Term Due:
                      <br />
                      <span> {{  nextTermDate  }}</span>
                    </span>
                     
                      <!-- <span
                        style="
                          font-weight: bold;
                          background-color: #93ed93;
                          border-radius: 4px;
                          font-size: small;
                        "
                        >Active</span
                      ></span
                    > -->
                  </div>
                </div>
             
              </div>
             
            </div>
            <!-- <div class="card minicards">
              <div class="card-body d-flex justify-content-between">
                <div class="info text-center p-2 mt-2" >
                  <span class="info_logo" ><img src="../../assets/img/Group 8.png" alt=""></span><br>
                  <span class="staff_counter" >Fee Payment</span>
                </div>
                <div class="button" style="margin-top: 16px;cursor: pointer;">
                  <button class="btn" 
                  @click.prevent="redirectRoute(`/parent/student/feePayment/${$route.params.id}`)"
                    style="height: 68px;
                    width: 78px;
                    border-radius: 50%;
                    border: 1px solid #2E2E2E;
                    font-size: 14px;
                    background-color: #b5b5eb">Check</button>
                </div>

              </div>
            </div>
            <div class="card minicards mt-4">
              <div class="card-body d-flex justify-content-between">
                <div class="info text-center p-2 mt-2">
                  <span class="info_logo"><img src="../../assets/img/certificate.svg" alt=""></span><br>
                  <span class="staff_counter">Certificate Request</span>
                </div>
                <div class="button" style="margin-top: 16px;">
                  <button class="btn" @click.prevent="redirectRoute(`/parent/student/tc/${$route.params.id}`)" style="height: 67px;
                  width: 83px;
                  border-radius: 50%;
                  border: 1px solid #2E2E2E;
                  font-size: 14px;
                  background-color: #b5b5eb">Request</button>
                </div>

              </div>
            </div> -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mt-3">
            <div class="card">
              <div class="card-body">
                <p>
                  <!-- <span class="info_logo" ><img src="../../assets/img/Group 78.png" alt=""></span><br>
                  <span class="staff_counter">My Profille</span> -->

                  <span class="d-flex align-items-center gap-2 card_left_text">
                    <span style="margin-left: 3px"> My Profille</span></span
                  >

                  <span style="float: right">
                    Class: <span style="font-weight: bold">{{  studentDetails.class ? studentDetails.class.className + " " + studentDetails.section.name : ''}}</span></span
                  >
                </p>
                <br />
                <div class="row">
                  <div
                    class="col-lg-4 col-md-6 col-sm-12"
                    style="text-align: center; margin-top: 11px"
                  >
                    10%
                    <!-- <div class="no-org no-cirlce">
                {{ onlineAdmissionList.length }}
              </div> -->
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <button
                      type="button"
                      class="btn btnsml mt-2"
                      id="svbtn"
                      style="width: 100%"
                      @click.prevent="
                        redirectRoute(
                          `/parent/student/portal/view/details/${$route.params.id}`
                        )
                      "
                    >
                      <span>View</span>
                    </button>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <span style="float: right; margin-top: 9px">
                      Status:
                      <span
                        style="
                          font-weight: bold;
                          background-color: #93ed93;
                          border-radius: 4px;
                          font-size: small;
                        "
                        >{{  studentDetails.admissionStatus || 'Active' }}</span
                      ></span
                    >
                  </div>
                </div>
                <!-- <span style="float: right;">   Class:  <span style="font-weight: bold;">LKS a</span></span> -->
                <!-- <div class="cin_cout flex-wrap justify-content-between" style="text-align: center;">
                  <button type="button" class="btn btnsml mt-2" id="svbtn" style="width: 100%;"
                  @click.prevent="redirectRoute(`/parent/student/portal/view/details/${$route.params.id}`)">
                    <span>View</span>

                    
                  </button>
                
                </div> -->
                <!-- <span style="float: right;">   Class:  <span style="font-weight: bold;">LKS a</span></span> -->
              </div>
            </div>
            <div class="card mt-4">
              <div class="card-body">
                <p>
                  <span class="d-flex align-items-center gap-2 card_left_text"
                    >
                    <span style="margin-left: 3px"> Notification</span></span
                  >
                  <span v-if="newAlertCount > 0" style="float: right;
    height: 27px;
    width: 29px;
    border-radius: 75px;
    color: white;
    background: rgb(252, 51, 51);
    padding-left: 12px;">
                    <!-- <div class="status-circle" style="width: 18px;
    height: 17px;
    color: white;
    font-size: 9px;" >{{ '1' }}</div> -->
    <span >{{ newAlertCount }}</span>
                    
                    </span
                  >
                </p>
                <br />

                <div
                  class="cin_cout flex-wrap justify-content-between"
                  style="text-align: center"
                >
                  <button
                    type="button"
                    class="btn btnsml mt-2"
                    id="svbtn"
                    style="width: 30%"
                    @click.prevent="
                      redirectRoute(
                        `/parent/student/alerts/${$route.params.id}`
                      )
                    "
                  >
                    <span>View</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- <div class="card mt-4">
            <div class="card-body">
              <p class="d-flex justify-content-between">
                <span class=" align-items-center gap-2 card_left_text"><img src="../../assets/img/Group 14.svg"
                    alt="birthday" /> <span style="margin-left: 3px;"> Leaves</span></span>
                    <span class="veiw_all">
                  <a style="color: blue" @click.prevent="navToLeave(`/parent/student/leave/request/${$route.params.id}`)"
                  >View All</a
                >
                </span>
              </p>
              <br>
                <div class="cin_cout flex-wrap justify-content-between" style="text-align: center;">
                  <button type="button" class="btn btnsml mt-2" id="svbtn" style="width: 30%;"
                  @click.prevent="openLeavePopUp">
                    <span>Apply Leave(s)</span>
                  </button>
                
                </div>
                



              
            </div>
          </div> -->
          </div>

          <!---------------------------------Student attendance------------------------->

          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <!-- -------------------------------Birthday card------------------------- -->
                <div class="card mt-4">
                  <div class="card">
                    <div class="card-body">
                      <p>
                        <span
                          class="d-flex align-items-center gap-2 card_left_text"
                          ><img
                            src="../../assets/img/birthday.png"
                            alt="birthday"
                          /><span style="margin-left: 3px">
                            Birthdays</span
                          ></span
                        >

                        <span class="veiw_all">
                          <a
                            style="color: blue; text-align: end"
                            @click.prevent="openBirthdaypopup"
                            >View All</a
                          ></span
                        >
                      </p>
                      <div class="t_body">
                        <table class="table birthday_table text-center">
                          <thead>
                            <tr>
                              <th>Student Name</th>
                              <!-- <th>Date of Birth</th> -->
                              <th>Class</th>
                              <th>Classroom</th>
                            </tr>
                          </thead>

                          <tbody>
                            <!-- Add your table rows here -->
                            <tr
                              v-for="(student, index) in birthdayStudentList"
                              :key="index"
                            >
                              <td>
                                {{ student.firstName + " " + student.lastName }}
                              </td>
                              <td>
                                {{
                                  student.class ? student.class.className : ""
                                }}
                              </td>
                              <td>
                                {{
                                  student.section ? student.section.name : ""
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <!---------------------holiday card------------------------->
                <div class="card mt-4">
                  <div class="card-body">
                    <p>
                      <span
                        class="d-flex align-items-center gap-2 card_left_text"
                        ><img
                          src="../../assets/img/holidays.svg"
                          alt="birthday"
                        /><span style="margin-left: 3px"> Holidays</span></span
                      >

                      <span class="veiw_all">
                        <a
                          style="color: blue; text-align: end"
                          @click.prevent="openHolidaypopup"
                          >View All</a
                        ></span
                      >
                    </p>
                    <div class="t_body">
                      <table class="table birthday_table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Name of holiday</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(student, index) in holidayList"
                            :key="index"
                          >
                            <td>{{ student.date | dateFormat }}</td>
                            <td>{{ student.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="leaveApplyPopUp"
      class="modal"
      no-close-on-backdrop
      no-close-on-esc
    >
      <button type="button" class="close" @click.prevent="closeLeavePopUp">
        ×
      </button>

      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Apply Leave</h2>
        <br />
        <div>
          <form
            data-vv-scope="admissionValidate"
            id="crtadmission"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              <div
                class="form-group col-md-2 mt-3 pr-md-3"
                style="padding-left: 66px"
              >
                Leave Type :
              </div>

              <div class="form-group input-group col-md-7 mt-3 pr-md-3">
                <!-- <span style="margin-top: 6px ;color: #2954a2;">Reason : </span> -->
                <div style="margin-top: 8px; width: 90%">
                  <label
                    class="custradio"
                    style="
                      margin-left: 6px;
                      margin-bottom: 0px;
                      border-radius: 21px;
                      width: 144px;
                    "
                    >Planned Leave
                    <input
                      type="radio"
                      v-model="leaveDetails.reason"
                      value="Planned Leave"
                      name="schedule"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="custradio"
                    style="
                      margin-bottom: 0px;
                      border-radius: 21px;
                      width: 110px;
                    "
                    >Sick Leave
                    <input
                      type="radio"
                      v-model="leaveDetails.reason"
                      value="Sick Leave"
                      name="schedule"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <!-- <label class="custradio" style="margin-bottom: 0px;
    border-radius: 21px;
    width: 50px;">Fever
								  <input type="radio" v-model="leaveDetails.reason" value="Fever" name="schedule" >
								  <span class="checkmark"></span>
								</label> -->
                  <label
                    class="custradio"
                    style="margin-bottom: 0px; border-radius: 21px; width: 0px"
                    >Others
                    <input
                      type="radio"
                      v-model="leaveDetails.reason"
                      value="Others"
                      name="schedule"
                    />
                    <span class="checkmark"></span>
                  </label>

                  <!-- <input type="radio" id="Planned Leave" name="options" v-model="leaveDetails.reason"
                      value="Planned Leave">
                    <label class="custLabel" for="Planned Leave">Planned Leave</label>

                    <input type="radio" id="Sick Leave" name="options" v-model="leaveDetails.reason" value="Sick Leave">
                    <label class="custLabel" for="Sick Leave">Sick Leave</label>

                    <input type="radio" id="Fever" name="options" v-model="leaveDetails.reason" value="Fever">
                    <label class="custLabel" for="Fever">Fever</label>
                    <input type="radio" id="Others" name="options" v-model="leaveDetails.reason" value="Others">
                    <label class="custLabel" for="Others">Others</label> -->
                </div>
              </div>
              <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
            </div>

            <div class="form-row">
              <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              <div
                class="form-group col-md-2 mt-3 pr-md-3"
                style="padding-left: 66px"
              >
                Comments :
              </div>

              <div class="form-group input-group col-md-5 mt-3 pr-md-3">
                <span class="has-float-label">
                  <textarea
                    class="form-control web-form-input"
                    style="
                      min-height: 135px;
                      resize: none;
                      border: 1px solid #ccc;
                    "
                    id="message"
                    v-model="leaveDetails.notes"
                  ></textarea>
                  <!-- <input
                      type="date"
                      v-model="leaveDetails.notes"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    /> -->

                  <!-- <label for="deptid" style="margin-top: -6px">Comments </label> -->
                </span>
              </div>
              <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div>
            </div>
            <div class="form-row">
              <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              <div
                class="form-group col-md-2 mt-3 pr-md-3"
                style="padding-left: 8px"
              >
                Is it half day leave? :
              </div>

              <div class="form-group input-group col-md-5 mt-3 pr-md-3">
                <div class="chkbox" data-title="Select">
                  <label class="custcheckbox">
                    <input
                      type="checkbox"
                      v-model="leaveDetails.isHalfDay"
                    />
                    <span class="checkmarkchk"></span>
                  </label>
                </div>
              </div>
              <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div>
            </div>
            <div class="form-row">
              <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              <div
                class="form-group col-md-2 mt-3 pr-md-3"
                style="padding-left: 77px"
              >
                From Date :
              </div>

              <div class="form-group input-group col-md-5 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    :min="minDatesLeaveApply"
                    :max="maxDatesLeaveApply"
                    v-model="leaveDetails.from"
                    onkeydown="return false"
                    @change="checkToDate"
                    class="form-control"
                    id="stdDob"
                  />

                  <!-- <label for="deptid">From Date <span class="required">*</span></label> -->
                </span>
              </div>

              <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div>
            </div>
            <div class="form-row" v-if="leaveDetails.from">
              <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              <div
                class="form-group col-md-2 mt-3 pr-md-3"
                style="padding-left: 84px"
              >
                Day Type :
              </div>

              <div class="form-group input-group col-md-7 mt-3 pr-md-3">
                <!-- <span style="margin-top: 6px;color: #2954a2; ">Day Type: </span> -->
                <div style="margin-top: 8px; width: 90%">
                  <label
                    class="custradio"
                    style="
                      margin-left: 6px;
                      margin-bottom: 0px;
                      border-radius: 21px;
                      width: 45px;
                    "
                    >Full
                    <input
                      type="radio"
                      v-model="leaveDetails.fromDayType"
                      value="Full"
                      name="scheduljkjke"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="custradio"
                    style="margin-bottom: 0px; border-radius: 21px; width: 88px"
                    >Forenoon
                    <input
                      type="radio"
                      v-model="leaveDetails.fromDayType"
                      value="Forenoon"
                      name="schedurffgle"
                    />
                    <span class="checkmark"></span>
                  </label>

                  <label
                    class="custradio"
                    style="margin-bottom: 0px; border-radius: 21px; width: 0px"
                    >Afternoon
                    <input
                      type="radio"
                      v-model="leaveDetails.fromDayType"
                      value="Afternoon"
                      name="schedulghge"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div
              class="form-row"
              v-if="!leaveDetails.isHalfDay && leaveDetails.from"
            >
              <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              <div
                class="form-group col-md-2 mt-3 pr-md-3"
                style="padding-left: 96px"
              >
                To Date :
              </div>

              <div class="form-group input-group col-md-5 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    :min="leaveDetails.from"
                    :max="maxDatesLeaveApply"
                    :disabled="!leaveDetails.from"
                    v-model="leaveDetails.to"
                    onkeydown="return false"
                    class="form-control"
                    id="stdDob"
                  />

                  <!-- <label for="deptid">To Date <span class="required">*</span></label> -->
                </span>
              </div>
              <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div>
            </div>
            <div
              class="form-row"
              v-if="
                !leaveDetails.isHalfDay &&
                leaveDetails.to &&
                leaveDetails.from != leaveDetails.to
              "
            >
              <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              <div
                class="form-group col-md-2 mt-3 pr-md-3"
                style="padding-left: 84px"
              >
                Day Type :
              </div>

              <div class="form-group input-group col-md-7 mt-3 pr-md-3">
                <!-- <span style="margin-top: 6px;color: #2954a2; ">Day Type: </span> -->
                <div style="margin-top: 8px; width: 90%">
                  <label
                    class="custradio"
                    style="
                      margin-left: 6px;
                      margin-bottom: 0px;
                      border-radius: 21px;
                      width: 45px;
                    "
                    >Full
                    <input
                      type="radio"
                      v-model="leaveDetails.toDayType"
                      value="Full"
                      name="schedussle"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="custradio"
                    style="margin-bottom: 0px; border-radius: 21px; width: 88px"
                    >Forenoon
                    <input
                      type="radio"
                      v-model="leaveDetails.toDayType"
                      value="forenoon"
                      name="schsdsdedule"
                    />
                    <span class="checkmark"></span>
                  </label>

                  <label
                    class="custradio"
                    style="margin-bottom: 0px; border-radius: 21px; width: 0px"
                    >Afternoon
                    <input
                      type="radio"
                      v-model="leaveDetails.toDayType"
                      value="Afternoon"
                      name="ssdsfdfchedule"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
            </div>
            <div
              class="form-row"
              v-if="
                (leaveDetails.isHalfDay && leaveDetails.from) ||
                (!leaveDetails.isHalfDay &&
                  leaveDetails.from &&
                  leaveDetails.to)
              "
            >
              <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              <div
                class="form-group col-md-2 mt-3 pr-md-3"
                style="padding-left: 66px"
              >
                No Of Days :
              </div>

              <div class="form-group input-group col-md-5 mt-3 pr-md-3">
                <!-- <span>No Of Days : </span>  -->
                <span style="margin-left: 7px">{{ noOfDaysLeave }} </span>
                <!-- <div>
                ssss
                </div> -->
              </div>
              <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div>
            </div>

            <div class="widjetfooter">
              <!-- <div class="dk_icon"></div> -->
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  :disabled="
                    !leaveDetails.from ||
                    (!leaveDetails.to && !leaveDetails.isHalfDay)
                  "
                  class="btn btnsml"
                  id="svbtn"
                  @click="updateLeaveApply"
                >
                  <span>Apply</span>
                </button>
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeLeavePopUp"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="birthDayPopUp"
      class="modal"
      no-close-on-backdrop
      no-close-on-esc
    >
      <button type="button" class="close" @click.prevent="closeBirthDayPopUp">
        ×
      </button>

      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>BirthDays</h2>
        <br />
        <div>
          <form
            data-vv-scope="admissionValidate"
            id="crtadmission"
            accept-charset="utf-8"
          >
            <div class="row">
              <div class="col-lg-12">
                <div class="widjet">
                  <div class="widjetcontent">
                    <div>
                      <div class="restable">
                        <div class="resrow resheader">
                          <div class="cell">Student Name</div>
                          <div class="cell">DOB</div>
                          
                          <div class="cell">Class</div>
                          <div class="cell">Classroom</div>
                        </div>
                        <div
                          class="resrow"
                          v-for="(student, index) in birthdayStudentAllList"
                          :key="index"
                        >
                          <div class="cell" data-title="Student Name">
                            {{ student.firstName + " " + student.lastName }}
                          </div>
                          <div class="cell" data-title="Student Name">
                            {{ student.dob | date }}
                          </div>
                          <!-- <td>{{ student.dob | date }}</td> -->

                          <div class="cell" data-title="Student Name">
                            {{ student.class ? student.class.className : "" }}
                          </div>
                          <div class="cell" data-title="Student Name">
                            {{ student.section ? student.section.name : "" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="widjetfooter mt-2">
              <!-- <div class="dk_icon"></div> -->
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeBirthDayPopUp"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal id="holidayPopUp" class="modal">
      <button type="button" class="close" @click.prevent="closeHolidayPopUp">
        ×
      </button>

      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Holiday</h2>
        <br />
        <div>
          <form
            data-vv-scope="admissionValidate"
            id="crtadmission"
            accept-charset="utf-8"
          >
            <div class="row">
              <div class="col-lg-12">
                <div class="widjet">
                  <div class="widjetcontent">
                    <div>
                      <div class="restable">
                        <div class="resrow resheader">
                          <div class="cell">Date</div>
                          <div class="cell">Name</div>
                        </div>
                        <div
                          class="resrow"
                          v-for="(student, index) in allHolidayList"
                          :key="index"
                        >
                          <div class="cell" data-title="Student Name">
                            {{ student.date | dateFormat }}
                          </div>
                          <div class="cell" data-title="Student Name">
                            {{ student.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="widjetfooter mt-3">
              <!-- <div class="dk_icon"></div> -->
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeHolidayPopUp"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    />
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
// import VueMonthlyPicker from "vue-monthly-picker";
// import NavBar from "@/views/ViNavBar.vue";

export default {
  name: "vi-parent-home",

  data() {
    return {
      viLoader: false,
      showToast: false,
      count: {
        academicYear: 0,
        admission: 0,
        class: 0,
        departMent: 0,
        student: 0,
        teacher: 0,
      },
      studentInformation: [],
      onlineAdmissionCount: 0,
      newAlertCount: 0,
      alertList: [],
      attendanceDetails: {},
      birthdayStudentList: [],
      birthdayStudentAllList: [],
      studentFeePlanList: [],
      isPendingAdhocfee: false,
      isPendingAdhocfeeStatus: '',

      nextTermDate: null,
      totalFeeTerms: 0,
      totalFeePaidTerms: 0,
      holidayList: [],
      allHolidayList: [],
      studentDetails: {},
      schoolDayObj: {},
      minDatesLeaveApply: null,
      maxDatesLeaveApply: null,
      leaveDetails: {
        from: null,
        to: null,
        notes: "",
        reason: "Planned Leave",
        isHalfDay: false,
        toDayType: "Full",
        fromDayType: "Full",
      },
    };
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    noOfDaysLeave() {
      if (this.leaveDetails.from) {
        let dates = [];
        let currentDate = null;

        if (this.leaveDetails.isHalfDay) {
          currentDate = new Date(this.leaveDetails.from);
        } else {
          currentDate = new Date(this.leaveDetails.to);
        }

        //  const currentDate = new Date(this.leaveDetails.to);
        const dd = new Date(this.leaveDetails.from);

        const firstDayOfMonth = new Date(
          dd.getFullYear(),
          dd.getMonth(),
          dd.getDate()
        ); // Get the first day of the month

        while (currentDate >= firstDayOfMonth) {
          const formattedDate = currentDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const dayValue = currentDate.toLocaleString("en-US", {
            weekday: "long",
          }); // Format the date as "Monday - May 29"

          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Months are zero-based, so we add 1
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          if (this.schoolDayObj[dayValue]) {
            dates.push({
              date: formattedDate,
              createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            }); // Add the formatted date and other data to the array
          }

          currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
        }
        if (this.leaveDetails.isHalfDay) {
          dates.forEach((x, i) => {
            x["noDays"] = this.leaveDetails.fromDayType == "Full" ? 1 : 0.5;
          });
        } else {
          dates.forEach((x, i) => {
            if (i == 0) {
              x["noDays"] = this.leaveDetails.fromDayType == "Full" ? 1 : 0.5;
            } else if (dates.length - 1) {
              x["noDays"] = this.leaveDetails.toDayType == "Full" ? 1 : 0.5;
            } else {
              x["noDays"] = 1;
            }

            // x['isDayStatus'] = this.leaveDetails.fromDayType || 'Full';
          });
        }
        let totalDays = dates
          .map((o) => Number(o.noDays))
          .reduce((a, c) => {
            return a + c;
          });
        return totalDays;
        // return dates.length
      } else {
        return 0;
      }
    },
  },
  created() {
    this.getOnlineTc();
    this.getAlertList(), this.getBirthdayStudentList();
    this.getHolidayList();
    this.getStudentDetails();
    // this.getBirthdayList()
  },
  methods: {
    redirectRoute(vipath) {
      localStorage.setItem("activeTab", vipath);
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    openBirthdaypopup() {
      this.$bvModal.show("birthDayPopUp");
    },
    closeHolidayPopUp() {
      this.$bvModal.hide("holidayPopUp");
    },
    openHolidaypopup() {
      this.$bvModal.show("holidayPopUp");
    },
    closeBirthDayPopUp() {
      this.$bvModal.hide("birthDayPopUp");
    },
    navToLeave(path) {
      this.$router.push(path);
    },
    async getStudentDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/contact/getStudentDetaiils/${this.$route.params.id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.studentDetails = response.data || {};
          if (
            this.studentDetails.accountSetting.workingDayList &&
            this.studentDetails.accountSetting.workingDayList.length > 0
          ) {
            //this.dayList = []

            const filteredObjects =
              this.studentDetails.accountSetting.workingDayList.filter(
                (obj) => obj.isselected
              );

            if (filteredObjects.length) {
              let dayList = filteredObjects.map((obj) => obj.name);

              const groupByDay = dayList.reduce((r, a) => {
                r[a] = a; // set the id
                return r;
              }, {});

              this.schoolDayObj = groupByDay;
            }
          }
          if(this.studentDetails && this.studentDetails._id && this.studentDetails.academicYear && this.studentDetails.academicYear._id){
            this.getStudentFeePlanList(this.studentDetails.academicYear._id)
          }
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async getStudentFeePlanList(academicYearId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.studentFeePlanList = [];
        const response = await ViService.viXGet(
          `/contact/getStudentFeePlanList?studentId=${this.$route.params.id}&academicYear=${academicYearId}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          this.studentFeePlanList = studentDetails;

          if(this.studentFeePlanList.length > 0){
            const checkAdhocFee = this.studentFeePlanList.filter( x => x.type == 'Adhoc-Fees' && x.status == 'Not Paid')
            if(checkAdhocFee.length > 0){
              this.isPendingAdhocfee = true
              this.isPendingAdhocfeeStatus = 'Due'
            }

            const checkAdhocFeelength = this.studentFeePlanList.filter( x => x.type == 'Adhoc-Fees')
          
            if(checkAdhocFeelength.length > 0){
             // this.isPendingAdhocfee = true
              this.isPendingAdhocfeeStatus = 'Paid'
            }

            const checkTermFee = this.studentFeePlanList.filter( x => x.type == 'Term-Fees')
            
            if(checkTermFee.length > 0){
              this.getBillingSummaryList(checkTermFee[0].feePlanId)
            }

            

            // this.nextTermDate = null,
            // this.totalFeeTerms = ''
          }
         
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getBillingSummaryList(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/contact/getBillingSummaryList?studentId=${this.$route.params.id}&feePlanId=${id}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          let allTermList = [];

          let studentBillingSummary = studentDetails.summaryList;
          
          if(Object.keys(studentBillingSummary).length > 0){
            for(let x of Object.keys(studentBillingSummary)){
              if(studentBillingSummary[x].status == 'Paid'){
                this.totalFeePaidTerms = (this.totalFeePaidTerms + 1)
              }
              if(studentBillingSummary[x].status != 'Paid'){
                allTermList = allTermList.concat(studentBillingSummary[x][x])
              }
            }
            this.totalFeeTerms = (Object.keys(studentBillingSummary).length)

            if(allTermList.length > 0){
              this.nextTermDate = allTermList[0].feesEndDate ? allTermList[0].feesEndDate.substring(0,10) : '-'
            }

            // this.nextTermDate = null,

          }

        //  this.studentBillingSummary = studentDetails.summaryList;


          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    // "/parent/student/leave/request/:id",
    openLeavePopUp() {
      // var newDate = new Date().add(30).days();

      var currentDate1 = new Date();
      var currentDate2 = new Date();
      var numberOfDaysToAdd = 30;

      let minDatesLeave = new Date(
        currentDate1.setDate(currentDate1.getDate() - numberOfDaysToAdd)
      );

      this.minDatesLeaveApply = this.dateFormat(minDatesLeave);

      let maxDatesLeave = new Date(
        currentDate2.setDate(currentDate2.getDate() + numberOfDaysToAdd)
      );

      this.maxDatesLeaveApply = this.dateFormat(maxDatesLeave);

      this.$bvModal.show("leaveApplyPopUp");
    },
    checkToDate() {
      this.leaveDetails.to = null;
    },

    dateFormat(value) {
      if (value) {
        let date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
      }
    },
    closeLeavePopUp() {
      this.$bvModal.hide("leaveApplyPopUp");
      this.leaveDetails.from = null;
      this.leaveDetails.to = null;
      (this.leaveDetails.notes = ""),
        (this.leaveDetails.reason = "Planned Leave"),
        (this.leaveDetails.toDayType = "Full"),
        (this.leaveDetails.fromDayType = "Full");
      this.leaveDetails.isHalfDay = false;
    },
    async getBirthdayStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          `/contact/getBirthdayStudentListView?studentId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          const today = new Date();
          var todayDate = today.getDate();
          var result = secureUI.secureGet(response.data);
          // this.birthdayStudentList = result.filter((x) => x.dobDay >= 15);
          this.birthdayStudentList = result.birthdayList;
          this.birthdayStudentAllList = result.birthdayListAll;

          this.attendanceDetails = result.attendance || {};
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getHolidayList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          `/contact/getHolidayStudentListView?studentId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);
          this.holidayList = data.hoildayList;
          this.allHolidayList = data.hoildayAllList;

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async updateLeaveApply() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (this.leaveDetails.isHalfDay) {
          this.leaveDetails.to = this.leaveDetails.from;
        }

        let dates = [];

        const currentDate = new Date(this.leaveDetails.to);
        const dd = new Date(this.leaveDetails.from);

        const firstDayOfMonth = new Date(
          dd.getFullYear(),
          dd.getMonth(),
          dd.getDate()
        ); // Get the first day of the month

        // this.currentMonth = currentDate.getMonth() + 1;

        while (currentDate >= firstDayOfMonth) {
          const formattedDate = currentDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const dayValue = currentDate.toLocaleString("en-US", {
            weekday: "long",
          }); // Format the date as "Monday - May 29"

          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Months are zero-based, so we add 1
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          if (this.schoolDayObj[dayValue]) {
            dates.push({
              date: formattedDate,
              createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            }); // Add the formatted date and other data to the array
          }

          currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
        }

        if (this.leaveDetails.isHalfDay) {
          dates.forEach((x, i) => {
            x["isFullDayLeave"] =
              this.leaveDetails.fromDayType == "Full" ? true : false;
            x["isDayStatus"] = this.leaveDetails.fromDayType || "Full";
            x["noDays"] = this.leaveDetails.fromDayType == "Full" ? 1 : 0.5;
          });
        } else {
          dates.forEach((x, i) => {
            if (i == 0) {
              x["isFullDayLeave"] =
                this.leaveDetails.fromDayType == "Full" ? true : false;
              x["isDayStatus"] = this.leaveDetails.fromDayType || "Full";
              x["noDays"] = this.leaveDetails.fromDayType == "Full" ? 1 : 0.5;
            } else if (dates.length - 1) {
              x["isFullDayLeave"] =
                this.leaveDetails.toDayType == "Full" ? true : false;
              x["isDayStatus"] = this.leaveDetails.toDayType || "Full";
              x["noDays"] = this.leaveDetails.toDayType == "Full" ? 1 : 0.5;
            } else {
              x["isFullDayLeave"] = true;
              x["isDayStatus"] = "Full";
              x["noDays"] = 1;
            }

            // x['isDayStatus'] = this.leaveDetails.fromDayType || 'Full';
          });
        }

        let obj = {
          from: this.leaveDetails.from,
          to: this.leaveDetails.to,
          notes: this.leaveDetails.notes,
          reason: this.leaveDetails.reason,
          isHalfDay: this.leaveDetails.isHalfDay || false,
          toDayType: this.leaveDetails.toDayType || "Full",
          fromDayType: this.leaveDetails.fromDayType || "Full",
          noOfDates: dates,
          type: "Student Leave",
          student: this.$route.params.id,
          status: "Pending Approval",
        };

        const response = await ViService.viXPost(
          "/home/leave/apply/student",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.closeLeavePopUp();
          this.$toasted.success("Leave Applied Successfully");
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async getOnlineTc() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          `/contact/getOnlineCertificateRequestDetails?studentId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          const onlineTcDetails = secureUI.secureGet(response.data);
          
          if(onlineTcDetails.requestList && onlineTcDetails.requestList.length > 0){
            this.onlineAdmissionCount = onlineTcDetails.requestList.filter( x => x.status == 'Submitted').length
          }
         // this.onlineAdmissionCount = onlineTcDetails.requestList.length || 0;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    viewStudentProfileAndPayemnt(studentId) {
      if (studentId) {
        this.$router.push(`/parent/student/portal/view/details/${studentId}`);
      }
    },
    async getAlertList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/contact/getalertListForstudent?studentId=${this.$route.params.id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.alertList = secureUI.secureGet(response.data);
          if(this.alertList.length > 0){
            this.newAlertCount = this.alertList.filter( x => x.isView == false).length
          }
          //if( isView: false,)
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    getStudentForParent() {
      let userData = secureUI.sessionGet("user");

      ViService.viXGet(
        "/contact/getStudentAndSchoolDetails/parent/portal",
        userData.token
      )
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              this.studentInformation = data;
            }
          } else {
            this.$toasted.show(res.message);
            if (res.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
  },
  components: {
    ViSpinner,
  },
};
</script>

<style lang="scss" scoped>
/* Hide the radio button */
input[type="radio"] {
  display: none;
}

/* Style the label to make it look clickable */
.custLabel {
  cursor: pointer;
  padding: 5px;
  background: beige;
  /* // border: 1px solid #ccc; */
  display: inline-block;
  margin: 5px;
  font-size: 0.89rem;
  border-radius: 10rem;
  /* color: #fff; */
  padding: 0.39rem 1.3rem 0.3rem 1.3rem;
  /* width: 140px; */
}

/* Style the label when it is clicked/selected */
input[type="radio"]:checked + label {
  background: #2954a2;
  /* background-color: #e0e0e0; */
}
</style>
